<template>
  <div v-if="canAccess('matriculas_comision_read')">
    <!-- <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI> -->
    <div class="p-grid">
      <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" class="p-col-12 p-breadcrumb p-component"/>
      <Fieldset legend="Matricula" :toggleable="false" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12">
            <div class="hide">
              {{ id = commissionData.enrollment?.contract?.holder?.id }}
              {{ nombre = commissionData.enrollment?.contract?.holder?.name + " " + commissionData.enrollment?.contract?.holder?.last_name }}
              {{ matricula = commissionData.enrollment?.sequential }}
              {{ plan = commissionData.enrollment?.plan?.name }}
              {{ asesor = commissionData.enrollment?.employee?.name }}
              {{ estado = commissionData.enrollment?.status }}
              {{ filiar = commissionData.enrollment?.subsidiary?.name }}
              {{ valor = commissionData.enrollment?.plan?.cost }}
              {{ mes = commissionData.enrollment?.month?.month }}
              {{ semana = commissionData.enrollment?.week?.week }}
              {{ mesP = commissionData.liq_month?.month_process }}
              {{ semanaP = commissionData.liq_week?.week_process }}
              {{ registro = commissionData.enrollment?.registration }}
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="matricula" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>No Matricula</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.status" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Estado de Matricula con Comisión</label>
                        </span>
            </div>
            <br>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="id" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Id Titular</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="nombre" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Nombre de Titular</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="plan" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Plan de Pago</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="asesor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Nombre de asesor</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="commissionData.created_at" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Fecha de Ingreso</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="filiar" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Filial</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="valor" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Valor Plan</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="registro" class="p-inputtext-sm p-col-12"
                                       :disabled="!edit"/>
                            <label>Fecha de verificación</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mes" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Mes Verificación</label>
                        </span>
            </div>
            <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semana" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Semana Verificación</label>
                        </span>
            </div>

            <template v-if="commissionData.invoice_number">
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="invoiceInput" :placeholder="commissionData.invoice_number"
                                       class="p-inputtext-sm p-filled ib p-col-12" @keydown.enter="updateIR"
                                       @blur="updateIR"/>
                            <label>No. Factura</label>
                        </span>
              </div>
            </template>
            <template v-else>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="invoiceInput" class="p-inputtext-sm ib p-col-12"
                                       @keydown.enter="updateIR" @blur="updateIR"/>
                            <label>No. Factura</label>
                        </span>
              </div>
            </template>
            <template v-if="commissionData.registry_number">
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="registryInput" :placeholder="commissionData.registry_number"
                                       class="p-inputtext-sm p-filled ib p-col-12" @keydown.enter="updateIR"
                                       @blur="updateIR"/>
                            <label>No. Registro</label>
                        </span>
              </div>
            </template>
            <template v-else>
              <div class="p-field p-md-3">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="registryInput" class="p-inputtext-sm ib p-col-12"
                                       @keydown.enter="updateIR" @blur="updateIR"/>
                            <label>No. Registro</label>
                        </span>
              </div>
            </template>
            <br/>

            <div class="p-field p-md-3" v-if="mesP">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="mesP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Mes Registro</label>
                        </span>
            </div>


            <div class="p-field p-md-3" v-if="semanaP">
                        <span class="p-float-label p-col-12">
                            <InputText type="text" v-model="semanaP" class="p-inputtext-sm p-col-12" :disabled="!edit"/>
                            <label>Semana Registro</label>
                        </span>
            </div>

          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Comisiones" :toggleable="False" class="p-unique">
        <ProgressBar mode="indeterminate" v-if="loading"/>
        <DataTable :value="items" :filters="filters" scrollable :key="items" class="table u">
          <template #header v-if="commissionData.status">
            <Toolbar class="p-col-12 p-mb-1">
              <template #right v-if="commissionData.status === 'Nuevo'">
                <Button label="Generar" icon="pi pi-plus" class="p-button-info p-mr-2"
                        @click="generar"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
              </template>
              <template #right v-else-if="commissionData.status === 'Generado'">
                <Button label="Volver a Generar" icon="pi pi-plus" class="p-button-danger p-mr-2"
                        @click="regenerar"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="statusChange('Aprobada')"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-info p-mr-2"
                        @click="getPMW"/>
              </template>
              <template #right v-else-if="commissionData.status === 'Aprobada'">
                <Button label="Generar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-warning p-mr-2"
                        @click="statusChange('Desaprobada')"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
              </template>
              <template #right v-else-if="commissionData.status === 'Desaprobada'">
                <Button label="Volver a Generar" icon="pi pi-plus" class="p-button-danger p-mr-2"
                        @click="regenerar"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="statusChange('Aprobada')"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-info p-mr-2"
                        @click="getPMW"/>
              </template>
              <template #right v-else-if="commissionData.status === 'Postergada'">
                <Button label="Volver a Generar" icon="pi pi-plus" class="p-button-danger p-mr-2"
                        @click="regenerar"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="statusChange('Aprobada')"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"
                        :disabled="!edit"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-info p-mr-2"
                        @click="getPMW"/>
              </template>
              <template #right v-else>
                <Button label="Generar" icon="pi pi-plus" class="p-button-secondary p-mr-2"/>
                <Button label="Aprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"/>
                <Button label="Desaprobar" icon="pi pi-plus" class="p-button-secondary p-mr-2"/>
                <Button label="Postergar" icon="pi pi-plus" class="p-button-secondary p-mr-2"/>
              </template>
            </Toolbar>
          </template>
          <template v-if="commissionData.status === 'Nuevo'">

            <Column v-for="col of columns" :header="col.header" :key="col.field">
            </Column>
          </template>
          <template v-else>
            <Column field="position.description" header="ASESOR" :sortable="true"></Column>
            <Column field="employee_with_ancestors.name" header="NOMBRE" :sortable="true" headerStyle="width: 350px">
              <template #body="slotProps">
                <Dropdown v-model="slotProps.data.employee_with_ancestors.list_employees"
                          :options="slotProps.data.employee_with_ancestors.list_employees"
                          optionLabel="name" :filter="true"
                          :placeholder="slotProps.data.employee_with_ancestors.name"
                          @change="nuevoEmpleado(slotProps.data.id, slotProps.data.employee_with_ancestors.list_employees.id)"/>
              </template>
            </Column>
            <Column field="cost" header="$ Comisión" :sortable="true"></Column>
            <Column field="approved" header="SE PAGA">
              <template #body="slotProps">
                <template v-if="slotProps.data.approved === 'n'">
                  <Checkbox @click="pagar(slotProps.data)" v-model="slotProps.data.approved" :binary="false"/>
                  <!-- {{slotProps.data.approved}} -->
                </template>
                <!-- <template v-if="slotProps.data.approved === 'n'">
                    {{slotProps.data.approved = true}}
                </template> -->
                <template v-else-if="slotProps.data.approved === 'y'">
                  <Checkbox @click="pagar(slotProps.data)" v-model="slotProps.data.approved"
                            :binary="slotProps.data.approved"/>
                  <!-- {{slotProps.data.approved}} -->
                </template>

              </template>

            </Column>
            <Column>
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                        @click="confirmDelete(slotProps.data)"/>
              </template>
            </Column>
          </template>
          <template #footer>
            <tr class="tFooter">
              <td>
                <Dropdown class="df"
                          optionLabel="position.description" v-model="selectedPosition" :options="positions"
                          placeholder="Posición" :disabled="newAproved" :filter="true"
                />
              </td>
              <td>
                <AutoComplete id="jef" :dropdown="true" :multiple="false" field="name" placeholder="Empleado"
                              v-model="selectedN" :suggestions="employees" :disabled="newAproved || selectedPosition===null" loading="true"
                              @complete="getEmployeeFilter($event)"/>
              </td>
              <td>
                <InputNumber showButtons :min="0" mode="currency" currency="USD" locale="en-US" currencyDisplay="symbol"
                             v-model="newC" :disabled="newAproved || selectedPosition===null || selectedN===null"/>
              </td>
              <td></td>
              <td>
                <Button icon="pi pi-save" class="p-button-success p-button-rounded" :disabled="newAproved || selectedPosition===null || selectedN===null" @click="newRow"/>
              </td>
            </tr>
          </template>
        </DataTable>
      </Fieldset>
      <Dialog v-model:visible="dialogo" :style="{width: '450px'}" header="Posponer" :modal="true">

        <div class="p-field   p-fluid  p-mr-2">
                                    <span class="p-float-label df">{{ selectedC?.country }}
                                    </span>
        </div>


        <div class="p-field   p-fluid  p-mr-2">
                            <span class="p-float-label df">
                            <Dropdown v-model="generateM" :options="months" optionLabel="month_process"
                                      @change="changeMG"/>
                                <label>Mes</label>
                            </span>
        </div>
        <div class="p-field   p-fluid  p-mr-2">
                            <span class="p-float-label df">
                            <Dropdown v-model="generateW" :options="weeks" optionLabel="week_process" @change="enable"/>
                                <label>Semana</label>
                            </span>
        </div>
        <template #footer>
          <Button label="Cancelar" icon="pi pi-times" class="p-button-text"
                  @click="dialogo = false"/>

          <Button v-if="!selected" disabled="true" label="Posponer" icon="pi pi-check" class="p-button-text"/>
          <Button v-if="selected" label="Posponer" icon="pi pi-check" class="p-button-text" @click="generate"/>
        </template>
      </Dialog>
      <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
          <span v-if="currentItem">Seguro de eliminar la comisión?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text"
                  @click="deleteDialog = false"/>
          <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow"/>
        </template>
      </Dialog>
      <Dialog v-model:visible="rewriteCommissions" :style="{width: '450px'}" header="Confirmar" :modal="true">
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
          <span>Estas seguro de generar nuevamente las comisiones?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text"
                  @click="rewriteCommissions = false"/>
          <Button label="Si" icon="pi pi-check" class="p-button-text" @click="generar"/>
        </template>
      </Dialog>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>
</template>
<script>
import commissionService from '../../service/processedEnrollments.service'
import commissionsService from '../../service/commissions.service'
import mw from '../../service/mw.service'
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  components: {},
  data() {
    return {
      employees: [],
      employeesMaster: [],
      loading: false,
      paymentsSidebar: false,
      editHolder: false,
      studentSidebar: false,
      contractSidebar: false,
      deleteDialog: false,
      editDialog: false,
      studentDialog: false,
      newAproved: false,
      rewriteCommissions: false,
      positionD: true,
      nameD: true,
      commissionData: {},
      items: null,
      columns: null,
      invoiceInput: null,
      registryInput: null,
      hi: null,
      filters: {},
      currentItem: null,
      pendingKey: 'Nuevo',
      new: {"status": ""},
      pago: {},
      selectedEmployee: {},
      generateM: null,
      generateW: null,
      selectedPosition: null,
      selectedN: null,
      selectedC: null,
      newC: null,
      countries: [],
      weeksG: [],
      positions: [],
      names: [],
      dialogo: false,
      selected: false,
    }
  },

  created() {
    if (this.canAccess('matriculas_comision_read')) {
      if (this.$route.params.id) {
        this.loading = true;
        commissionService.get(this.$route.params.id).then(x => {
          this.commissionData = x.data;
          this.breadcrumbCreate(x.data.enrollment.sequential)
        }).finally(() => this.loading = false);
        this.getData();
      }
    }

  },

  mounted() {
    if (this.canAccess('matriculas_comision_read')) {
      this.columns = [
        {field: 'position.description', header: 'ASESOR'},
        {field: 'employee_with_ancestors.name', header: 'NOMBRE'},
        {field: 'cost', header: '$ Comisión'},
        {field: 'approved', header: 'SE PAGA'},
      ];
      commissionsService.getEmployees("").then(x => {
        this.employees = x.data
        this.employeesMaster = x.data
      })
    }
  },
  computed: {
    ...mapGetters('bread', ['breadcrumb']),
    ...mapState('auth', ['isAdmin']),
    months() {
      return this.selectedC ? this.selectedC.months : [];
    },
    weeks() {
      return this.generateM ? this.generateM.weeks : [];
    }
  },
  methods: {
    getEmployeeFilter(event){

      this.employees = this.employeesMaster.filter((x) => {
        return x['name'].toLowerCase().includes(event.query.toLowerCase());
      });

      return this.employees;
    },
    breadcrumbCreate(matriculaId) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Registro', url: "javascript:void(0);"},
        {label: 'Lista de Matrículas con Comisión', url: "javascript:void(0);"},
        {label: 'Detalle Matricula con Comisión ' + matriculaId, url: "javascript:void(0);"},
      ]);
    },
    getData() {
      this.loading = true;
      this.newC = 0
      commissionsService.get(this.$route.params.id).then(x => {
        this.items = x.data?.commissions;
      }).finally(() => this.loading = false).catch(err => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });

      commissionService.get(this.$route.params.id).then(x => {
        this.commissionData = x.data;
        this.populatePositions(x.data.enrollment.plan_id)
        if (this.commissionData.status === "Nuevo" || this.commissionData.status === "Aprobada") {
          this.newAproved = true
        } else {
          this.newAproved = false
        }
      }).finally(() => this.loading = false).catch(err => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });

    },
    populatePositions(id) {
      commissionsService.getPosition(id).then(x => {
        this.positions = x.data;
      })
    },
    deleteRow() {
      commissionsService.delete(this.currentItem.id).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Eliminado correctamente',
          life: this.$utils.toastLifeTime()
        });
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.deleteDialog = false;
        this.getData();
      })
    },
    confirmDelete(val) {
      this.currentItem = val;
      this.deleteDialog = true;
    },
    regenerar() {
      this.rewriteCommissions = true;
    },
    generar() {
      this.rewriteCommissions = false;
      commissionsService.generate(this.commissionData.id).then(() => {
        if (this.commissionData.status !== "Postergada") {
          this.commissionData.status = "Generado";
        }
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Comisiones generadas',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });
    },
    nuevoEmpleado(id, newEmployee) {

      this.loading = true
      commissionsService.save({"employee_id": newEmployee}, id).then(() => {
        this.items = []
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Empleado cambiado',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.loading = false;
        // this.getData();
      })
    },
    pagar(pago) {
      if (pago.approved === 'n' || pago.approved === false) {
        this.pago = {
          "approved": "y",
          "employee_id": pago.employee_id
        }
        commissionsService.save(this.pago, pago.id).then(() => {
          this.getData()
          this.$toast.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Se paga exitoso',
            life: this.$utils.toastLifeTime()
          });
          this.$nextTick(() => {
            this.$emit('closeSidebar', false)
          })
        }).catch((err) => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        });
      } else if (pago.approved === 'y' || pago.approved === true) {
        this.pago = {
          "approved": "n",
          "employee_id": pago.employee_id
        }
        commissionsService.save(this.pago, pago.id).then(() => {
          this.getData()
          this.$toast.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'No se paga exitoso',
            life: this.$utils.toastLifeTime()
          });
          this.$nextTick(() => {
            this.$emit('closeSidebar', false)
          })
        }).catch((err) => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        });
      } else {
        return pago
      }
    },
    statusChange(newStatus) {
      this.new = {"status": newStatus}
      commissionService.save(this.new, this.$route.params.id).then(() => {
        this.commissionData.status = newStatus
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Guardado correctamente',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });
    },
    updateIR() {
      var data
      if (this.registryInput) {
        data = {
          "registry_number": this.registryInput
        }
      } else if (this.invoiceInput) {
        data = {
          "invoice_number": this.invoiceInput
        }
      }
      commissionService.save(data, this.$route.params.id).then(() => {
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Datos actualizados',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      });
    },
    changeMG() {
      this.weeksG = this.generateM.weeks
    },

    newRow() {
      this.positionD = this.nameD = true
      const employee = this.selectedN !== null ? this.selectedN.id : undefined;
      const position = this.selectedPosition !== null ? this.selectedPosition.position.id : undefined;
      const cost = this.newC !== null ? this.newC : undefined;
      const data = {
        "employee_id": employee,
        "approved": "y",
        "position_id": position,
        "cost": cost
      }
      this.loading = true
      commissionsService.addRow(this.$route.params.id, data).then(() => {
        this.selectedN = this.selectedPosition = null
        this.newC = 0
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Comisión agregada',
          life: this.$utils.toastLifeTime()
        });
        this.$nextTick(() => {
          this.$emit('closeSidebar', false)
        })
      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
          .finally(() => this.loading = false)
    },
    getPMW() {
      this.dialogo = true
      mw.getMWLiqPost(this.commissionData.enrollment?.contract?.country_id, this.commissionData.liq_week_id).then(x => {
        this.countries = x.data
        this.selectedC = this.countries[0];

      })
    },
    enable() {
      this.selected = true
    },
    generate() {
      this.loading = true;
      if (this.generateW !== null) {
        const monthWeek = {
          ...{'month_postpone': this.generateM.id},
          ...{'week_postpone': this.generateW.id},
          ...{"status": 'Postergada'}
        }
        commissionService.save(monthWeek, this.$route.params.id).then(() => {
          this.commissionData.status = 'Postergada'
          this.selected = false
          this.dialogo = false
          this.getData()
          this.$toast.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Liquidaciones postergadas',
            life: this.$utils.toastLifeTime()
          });
          this.$nextTick(() => {
            this.$emit('closeSidebar', false)
          })
        }).catch((err) => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        })
            .finally(() => this.loading = false)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.p-progressbar {
  height: 1px;
}

.hide {
  display: none;
}

.p-field {
  display: inline-flex;
}

.p-unique {
  margin-top: 1rem;
}

.p-breadcrumb {
  margin: 1.6rem auto;
  padding: 2rem;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right {
  width: 100%;
}

.p-float-label label {
  margin-left: .5rem;
}

.p-datatable {
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;

  &-header {
    .p-toolbar {
      background: none;
      border: none;
    }
  }
}

.ib {
  border: 1px solid #2196F3;;
}

.tFooter {
  width: 100%;
  display: flex;
  padding: .5rem;

  td {

    width: 20%;

    div, span, input {
      width: 100%;
    }

    &:last-child {
      width: 2.5%;
      // padding-left: 2.75rem;
    }
  }
}

.df, .dff {
  height: 100%;
}

// .p-dropdown-panel {
//     z-index: 0 !important;
//     position: relative !important;
//     display: block;
//     top: 0 !important;
//     left: -43px !important;
// }

.u .p-datatable-scrollable-wrapper .p-dropdown-panel {
  position: relative !important;
  top: 0 !important;
  left: -3rem !important;
}


</style>
