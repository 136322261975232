import httpService from '../../../helpers/http.service'

export default {
    getMW(){
        return httpService.get(`select/dependence/months/weeks`)
    },
    getPMW(m,w){
        return httpService.get(`select/dependence/months/weeks/post/${m}/${w}`)
    },
    getMWLiq(){
        return httpService.get(`select/countries/mw/liquidations`)
    },
    getMWSELiq(){
        return httpService.get(`select/countries/mwse/liquidations`)
    },
    getMWLiqPost(country,week){
        return httpService.get(`select/countries/mw/liquidations/post/${country}?week=${week}`)
    },
}